.adm-error-block {
  --color: var(--adm-color-text);
  --image-height: var(--adm-error-block-image-height, 100px);
  --image-height-full-page: var(--adm-error-block-image-height-full-page, 200px);
  --image-width: var(--adm-error-block-image-width, auto);
  --image-width-full-page: var(--adm-error-block-image-width-full-page, auto);
  box-sizing: border-box;
  text-align: center;
}
.adm-error-block-image {
  height: var(--image-height);
  width: var(--image-width);
  max-width: 100%;
}
.adm-error-block-image svg,
.adm-error-block-image img {
  height: 100%;
}
.adm-error-block-description {
  font-size: var(--adm-font-size-4);
  color: #999;
  line-height: 1.4;
  margin-top: 12px;
}
.adm-error-block-description-title {
  font-size: var(--adm-font-size-7);
}
.adm-error-block-description-subtitle {
  margin-top: 8px;
}
.adm-error-block-content {
  margin-top: 12px;
}
.adm-error-block-full-page {
  padding-top: calc(50vh - var(--image-height-full-page));
}
.adm-error-block-full-page .adm-error-block-image {
  height: var(--image-height-full-page);
  width: var(--image-width-full-page);
}
.adm-error-block-full-page .adm-error-block-description {
  margin-top: 20px;
  font-size: var(--adm-font-size-main);
}
.adm-error-block-full-page .adm-error-block-description-title {
  font-size: 20px;
  color: var(--adm-color-text);
}
