.adm-nav-bar {
  --height: 45px;
  --border-bottom: none;
  display: flex;
  align-items: center;
  height: var(--height);
  border-bottom: var(--border-bottom);
  padding: 0 12px;
  white-space: nowrap;
}
.adm-nav-bar-left,
.adm-nav-bar-right {
  flex: 1;
}
.adm-nav-bar-title {
  flex: auto;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.adm-nav-bar-back {
  display: flex;
  align-items: center;
  margin-right: 16px;
  padding: 6px 0;
  cursor: pointer;
}
.adm-nav-bar-back-arrow {
  font-size: 24px;
  margin-right: 4px;
}
.adm-nav-bar-left {
  font-size: var(--adm-font-size-7);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.adm-nav-bar-title {
  justify-content: center;
  white-space: nowrap;
  font-size: var(--adm-font-size-10);
  padding: 0 12px;
}
.adm-nav-bar-right {
  text-align: right;
}
