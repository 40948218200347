.adm-skeleton {
  --width: 100%;
  --height: 0;
  --border-radius: 0;
  background-color: rgba(190, 190, 190, 0.2);
  border-radius: var(--border-radius);
  width: var(--width);
  height: var(--height);
  display: block;
}
.adm-skeleton.adm-skeleton-animated {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation: adm-skeleton-loading 1.4s ease infinite;
}
.adm-skeleton.adm-skeleton-title {
  --width: 45%;
  --height: 32px;
  --border-radius: 2px;
  margin-bottom: 16px;
  margin-top: 16px;
}
.adm-skeleton.adm-skeleton-paragraph-line {
  --height: 18px;
  --border-radius: 2px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.adm-skeleton.adm-skeleton-paragraph-line:last-child {
  --width: 65%;
}
@keyframes adm-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
