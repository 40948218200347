:root:root {
  --font-family: 'Jost', sans-serif;

  @supports (font-variation-settings: normal) {
    --font-family: 'JostVariable', sans-serif;
  }
}

:root:root {
  // colors
  --color-primary: #cee46a;
  --color-primary-light: #deeba3;
  --color-primary-medium: #a6cf03;
  --color-primary-dark: #79950d;
  --color-black: #000000;
  --color-gray6: #424242;
  --color-gray5: #333333;
  --color-gray4: #999999;
  --color-gray3: #bfbfbf;
  --color-gray2: #dcdcdc;
  --color-gray1: #f2f2f2;
  --color-white: #ffffff;
  --color-success: var(--color-primary-medium);
  --color-danger: #ff5050;
  --color-danger-bg: #fff5f5;
  --color-warning: #ffd80b;
  --color-warning-bg: #fffae0;

  // spacing
  --spacing-xxs: 2px;
  --spacing-xs: 4px;
  --spacing-s: 6px;
  --spacing-m: 8px;
  --spacing-l: 12px;
  --spacing-xl: 14px;
  --spacing-xxl: 16px;

  // font sizes
  --font-size1: 12px;
  --font-size2: 12px;
  --font-size3: 14px;
  --font-size4: 22px;
  --font-size5: 24px;
  --font-size6: 26px;
  --font-size7: 28px;
  --font-size8: 32px;
  --font-size16: 16px;
  --font-size14: 14px;
  --font-size12: 12px;

  // layouts
  --nav-bar-mobile-height: 46px;
  --nav-bar-desktop-height: 108px;
  --notice-bar-height: 36px;
  --tab-bar-height: 50px;
  --secondary-nav-bar-height: 44px;
  --content-max-width: 1280px;
  --content-small-max-width: 620px;

  // override Ant Design Mobile theme variables
  --adm-font-family: var(--font-family);
  --adm-color-primary: var(--color-primary);
  --adm-color-success: var(--color-success);
  --adm-color-warning: var(--color-warning);
  --adm-color-danger: var(--color-danger);
  --adm-color-white: var(--color-white);
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-border-color: #eeeeee;
  --adm-font-size-main: 13px;
  --adm-color-text: var(--color-black);

  --ds2-box-shadow: 0 2px 10px #0000000f;

  --section-header-font-size: 16px;
  --section-text-font-size: 14px;

  --p-action-primary: #cee46a;
  --p-action-primary-hovered: #a6cf03;
  --p-action-primary-pressed: #79950d;
  --p-border-highlight: #cee46a;
  --p-divider: #f5f5f5;
  --p-interactive: #cee46a;
}

:global {
  .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
    .ant-input-suffix .ant-input-clear-icon {
      font-size: 16px;
    }
  }
}
