.adm-infinite-scroll {
  color: var(--adm-color-weak);
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--adm-font-size-main);
}
.adm-infinite-scroll-failed-text {
  display: inline-block;
  margin-right: 8px;
}
