.adm-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
}
.adm-empty-image-container {
  display: flex;
  justify-content: center;
}
.adm-empty-image-container .adm-empty-image {
  width: 64px;
  height: intrinsic;
}
.adm-empty-description {
  margin-top: 8px;
  font-size: var(--adm-font-size-6);
  color: var(--adm-color-light);
}
