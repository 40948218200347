.adm-radio {
  --icon-size: 22px;
  --font-size: var(--adm-font-size-9);
  --gap: 8px;
  display: inline-flex;
  vertical-align: text-bottom;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.adm-radio input {
  display: none;
}
.adm-radio .adm-radio-icon {
  flex: none;
  border: 1px solid var(--adm-color-light);
  border-radius: var(--icon-size);
  box-sizing: border-box;
  width: var(--icon-size);
  height: var(--icon-size);
  color: var(--adm-color-white);
}
.adm-radio .adm-radio-icon > svg {
  display: block;
  width: 100%;
  height: 100%;
}
.adm-radio.adm-radio-block {
  display: flex;
}
.adm-radio.adm-radio-checked .adm-radio-icon {
  border-color: var(--adm-color-primary);
  background-color: var(--adm-color-primary);
}
.adm-radio.adm-radio-disabled {
  cursor: not-allowed;
}
.adm-radio.adm-radio-disabled .adm-radio-content {
  opacity: 0.4;
}
.adm-radio.adm-radio-disabled .adm-radio-icon {
  color: #b7b7b7;
  border-color: var(--adm-color-light);
  background-color: var(--adm-color-box);
}
.adm-radio .adm-radio-custom-icon {
  font-size: var(--icon-size);
}
.adm-radio-content {
  flex: 0 1 auto;
  font-size: var(--font-size);
  padding-left: var(--gap);
}
